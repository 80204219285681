<template>
  <div>
    <!-- Miscellaneous Charts -->
    <!-- <b-row class="match-height">
      <b-col
        lg="6"
        cols="12"
        sm="12"
      >
        <statistic-card-horizontal
          color="primary"
          icon="UsersIcon"
          :statistic="stats.total"
          statistic-title="Admins"
          :loaded="loaded"
        />
      </b-col>
      <b-col
        lg="6"
        cols="12"
        sm="12"
      >
        <statistic-card-horizontal
          color="info"
          icon="UsersIcon"
          :statistic="stats.active"
          statistic-title="Active Admins"
          :loaded="loaded"
        />
      </b-col>
      <b-col
        lg="4"
        cols="12"
        sm="12"
      >
        <statistic-card-horizontal
          color="danger"
          icon="UsersIcon"
          :statistic="stats.inactive"
          statistic-title="Inactive Admins"
          :loaded="loaded"
        />
      </b-col>
    </b-row> -->
    <!--/ Miscellaneous Charts -->

    <b-card
      class="remove-td-tr-padding admins-table-wrapper"
    >
      <b-row>
        <b-col>
          <add-new-admin
            :is-add-new-user-sidebar-active.sync="isAddNewSlideSidebarActive"
            :editing="admin"
            @close="admin = null"
            @refetch-data="load"
          />
          <h2>Admins List</h2>
        </b-col>
        <b-col>
          <b-button
            v-if="$ableTo('Create Admin')"
            class="float-right mb-1"
            @click="isAddNewSlideSidebarActive = true"
          >
            <feather-icon icon="PlusIcon" />
            Add New
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <new-table
            v-if="loaded"
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            table-name="admins-users-list"
            @edit="edit"
            @resetPassword="resetPassword"
            @toggle="toggle"
            @remove="remove"
          />
          <widget-loader v-else />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BButton, BCol, BRow,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import AddNewAdmin from '@/views/admins/AddNewAdmin.vue'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    BCard,
    AddNewAdmin,
    BButton,
    BCol,
    BRow,
    StatisticCardHorizontal,
    NewTable,
    WidgetLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loaded: false,
      moment: Moment,
      userStatus: [
        'Inactive',
        'Active',
      ],
      stats: {
        total: 0,
        inactive: 0,
        active: 0,
      },
      admin: null,
      loading: false,
      isAddNewSlideSidebarActive: false,
      items: [],
      exportFileName: `admins-list-${Moment(new Date()).format('YYYY-MM-DD')}`,
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Phone Number', sortable: true },
        { key: 'position', label: 'Position', sortable: true },
        { key: 'last_login', label: 'Last Login', sortable: true },
        { key: 'is_active', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],

      columns: [
        {
          label: 'Name',
          data: 'name',
        },
        {
          label: 'Email',
          data: 'email',
        },
        {
          label: 'Role',
          data: 'role_name',
        },
        {
          label: 'Position',
          data: 'position',
        },
        {
          label: 'Last login',
          data: 'last_login',
        },
        {
          label: 'is active',
          data: 'is_active',
        },
        {
          label: 'Actions',
          data: 'actions',
          custom_template: {
            template_name: 'admins.users.list.actions',
          },
          filterable: false,
          sortable: false,
        },
      ],

      statisticsItems: [
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Active',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Inactive',
          customClass: 'mb-2 mb-xl-0',
        },
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    resetPassword(admin) {
      // this.loading = true
      // this.loaded = false
      this.$http.post(this.$resources.admins.resetPassword, {
        email: admin.email,
      }).then(() => {
        // this.loading = false
        this.$notify({ type: 'success', title: 'New password', text: 'New password Sent Successfully' })
      })
    },
    edit(item) {
      this.admin = item
      this.isAddNewSlideSidebarActive = true
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          headerBgVariant: 'danger',
          okVariant: 'danger',
        })
        .then(value => {
          if (value) {
            this.loading = true
            this.$http.delete(this.$resources.admins.destroy.replace(':id', item.id)).then(() => {
              this.loading = false
              this.$notify({ type: 'success', title: 'Remove admin', text: 'Admin removed successfully' })
              this.load()
            })
          }
        })
    },
    toggle(item) {
      this.loading = true
      this.$http.post(this.$resources.admins.toggle.replace(':id', item.id)).then(() => {
        this.loading = false
        this.$notify({ type: 'success', title: !item.is_active ? 'Activated' : 'Deactivated', text: `Admin  ${!item.is_active ? 'activated' : 'deactivated'} successfully` })
        this.load()
      })
    },
    load() {
      this.loading = true
      this.loaded = false
      this.$http.get(this.$resources.admins.index).then(response => {
        const { data: { status, data: { stats, rows } } } = response
        if (status === 200) {
          this.items = rows
          this.stats = stats
          this.loading = false
          this.loaded = true
        }
      })
    },
  },
}
</script>

<style>
.remove-td-tr-padding th, .remove-td-tr-padding  td {
  padding: 0.72rem !important;
}
.admins-table-wrapper {
  min-height: 1095px;
}
</style>
