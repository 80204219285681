<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5
          v-if="editing && editing.id"
          class="mb-0"
        >
          Edit Admin
        </h5>
        <h5
          v-else
          class="mb-0"
        >
          Add New Admin
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- BODY -->
      <validation-observer
        v-else
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validateForm"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Photo"
          >
            <b-form-group
              label="Photo"
              label-for="photo"
            >
              <b-form-file
                id="photo"
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required|max:191"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="adminData.name"
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email|max:191"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="adminData.email"
                type="email"
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Password"
            :rules="{ required: !!!adminData.id, max: 16, min: 8 }"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="adminData.password"
                type="password"
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Role"
            :rules="{ required }"
          >
            <b-form-group
              label="Role"
              label-for="rule"
            >
              <b-form-select
                v-model="adminData.role_id"
                label="rule"
                :options="rolesOptions"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Number"
            rules="max:191"
          >
            <b-form-group
              label="Number"
              label-for="number"
            >
              <b-form-input
                id="number"
                v-model="adminData.phone"
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="position"
            rules="required|max:191"
          >
            <b-form-group
              label="Position"
              label-for="position"
            >
              <b-form-input
                id="position"
                v-model="adminData.position"
                :state="validationContext.errors.length > 0 ? false : null"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="status"
            :rules="{ required }"
          >
            <b-form-group
              label="User status"
              label-for="status"
            >
              <b-form-select
                v-model="adminData.is_active"
                label="Status"
                :options="statusOptions"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--
            form
            actions
            -->
          <div class="d-flex mt-2">
            <b-button
              v-if="editing && editing.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
  BFormFile,
  BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  alphaNum,
  email,
  url,
  mimes,
  integer,
  max,
  min,
} from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    editing: {
      type: Object,
      default() {
        return null
      },
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      rolesOptions: [],
      statusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      adminData: {
        email: null,
        password: null,
        name: null,
        role_id: null,
        phone: null,
        position: null,
        is_active: 1,
        image: null,
      },
      max,
      min,
      required,
      alphaNum,
      email,
      url,
      mimes,
      integer,
    }
  },
  watch: {
    isAddNewUserSidebarActive() {
      this.load()
    },
    editing() {
      if (this.editing && this.editing.id) {
        this.loading = true
        this.$http
          .get(this.$resources.admins.fetch.replace(':id', this.editing.id))
          .then(response => {
            const {
              data: { status, data },
            } = response
            if (status === 200) {
              this.adminData = { ...data.admin, password: null }
              this.loading = false
            }
          })
      }
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$http.get(this.$resources.roles.index).then(response => {
        this.rolesOptions = response.data.data.rows.map(row => ({
          text: row.name,
          value: row.id,
        }))
        this.loading = false
      })
    },
    resetForm() {
      this.adminData = {
        email: null,
        password: null,
        name: null,
        role_id: null,
        phone: null,
        position: null,
        status: 1,
        image: null,
      }
      this.$emit('close', 1)
    },
    validateForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.loading = true
          const formData = new FormData()

          Object.keys(this.adminData).forEach(key => {
            if (this.adminData[key] !== null) { formData.append(key, this.adminData[key]) }
          })

          this.$http
            .post(
              this.editing && this.editing.id
                ? this.$resources.admins.update.replace(':id', this.editing.id)
                : this.$resources.admins.store,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart',
                },
              },
            )
            .then(response => {
              const {
                data: { status },
              } = response

              if (status === 200) {
                this.$notify({
                  type: 'success',
                  title: 'Admin added Successfully',
                  text: 'Admin added Successfully',
                })
                this.$emit('refetch-data', 1)
                this.$emit('update:is-add-new-user-sidebar-active', false)
              } else if (status === 422) {
                this.$notify({
                  type: 'danger',
                  title: 'Validation error',
                  text: 'Email is used',
                })
                this.$refs.projectForm.setErrors({ email: ['Email is usd.'] })
              } else {
                this.$notify({
                  type: 'danger',
                  title: 'Something went wrong',
                  text: 'Service is under-maintenance currently',
                })
              }
              this.loading = false
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.$notify({
                  type: 'danger',
                  title: 'Validation error',
                  text: 'Email is used',
                })
              } else {
                this.$notify({
                  type: 'danger',
                  title: 'Something went wrong',
                  text: 'Service is under-maintenance currently',
                })
              }
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
